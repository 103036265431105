
       if (typeof window !== 'undefined') {
         function loadSvg() {
           var body = document.body;
           var svgDom = document.getElementById('__svg__icons__dom__');
           if(!svgDom) {
             svgDom = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
             svgDom.style.position = 'absolute';
             svgDom.style.width = '0';
             svgDom.style.height = '0';
             svgDom.id = '__svg__icons__dom__';
             svgDom.setAttribute('xmlns','http://www.w3.org/2000/svg');
             svgDom.setAttribute('xmlns:link','http://www.w3.org/1999/xlink');
           }
           svgDom.innerHTML = "<symbol viewBox=\"0 0 1024 1024\" id=\"icon-house\"><path fill=\"currentColor\" d=\"M192 413.952V896h640V413.952L512 147.328 192 413.952zM139.52 374.4l352-293.312a32 32 0 0 1 40.96 0l352 293.312A32 32 0 0 1 896 398.976V928a32 32 0 0 1-32 32H160a32 32 0 0 1-32-32V398.976a32 32 0 0 1 11.52-24.576z\" /></symbol><symbol class=\"icon\" viewBox=\"0 0 1024 1024\"  id=\"icon-select_subject\"><path d=\"M826.422 0H280.468c-53.895 0-97.441 54.757-97.441 108.598h628.412v791.39c53.949 0 112.856-43.708 112.856-97.98V97.98A98.196 98.196 0 0 0 826.422 0zm-144.06 155.379h-32.176V419.3l-87.525-101.484-89.627 101.484V155.38H134.575c-44.463 0-80.68 34.438-80.68 77.23v713.729c0 42.792 36.27 77.662 80.626 77.662h547.84c44.41 0 80.627-34.87 80.627-77.662V232.61c-.431-42.793-36.217-77.231-80.627-77.231zm-32.176 674.87a8.03 8.03 0 0 1-8.192 7.922H174.888a8.03 8.03 0 0 1-8.192-7.923v-46.35c0-4.418 3.665-7.975 8.192-7.975h467.106c4.527 0 8.192 3.557 8.192 7.976v46.35zm0-124.497a8.03 8.03 0 0 1-8.192 7.976H174.888a8.03 8.03 0 0 1-8.192-7.976v-46.35c0-4.42 3.665-7.922 8.192-7.922h467.106c4.527 0 8.192 3.503 8.192 7.922v46.35zm0-124.012a8.03 8.03 0 0 1-8.192 7.922H174.888a8.03 8.03 0 0 1-8.192-7.922v-46.35c0-4.42 3.665-7.922 8.192-7.922h467.106c4.527 0 8.192 3.503 8.192 7.922v46.35z\" fill=\"#24a7fc\" /></symbol>";
           body.insertBefore(svgDom, body.lastChild);
         }
         if(document.readyState === 'loading') {
           document.addEventListener('DOMContentLoaded', loadSvg);
         } else {
           loadSvg()
         }
      }
        
export default {}