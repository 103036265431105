<script setup lang="ts">
import { onLaunch, onShow, onHide } from "@dcloudio/uni-app";
onLaunch(() => {});
onShow(() => {});
onHide(() => {});
</script>
<style>
.flex {
    display: flex;
}

.justify-between {
    justify-content: space-between;
}

.items-center {
    align-items: center;
}

.train-item-icon {
    display: inline-block;
    width: 30px;
    height: 30px;
    line-height: 30px;
    background-repeat: no-repeat;
    background-size: cover;
}

.text-center {
    text-align: center;
}

.qu-options {
    padding-left: 10px;
}
</style>
