import { createSSRApp } from "vue";
import App from "./App.vue";
import Vant from "vant";
import "vant/lib/index.css";
import pinia from "./store";
// 组件导入
import componentPlugin from "@/components";

//  注册SVG插件
import "virtual:svg-icons-register";

export function createApp() {
    const app = createSSRApp(App);

    app.use(pinia);
    app.use(Vant);
    app.use(componentPlugin);

    return {
        app,
    };
}
